import React from "react";
import 'font-awesome/css/font-awesome.min.css';
import '../styles/headerButton.css';

const HeaderButton = ({ text, onClick }) => {
    return (
        <button className="header-button" onClick={onClick}>
            {text} <i className={'fa fa-arrow-right'}></i>
        </button>
    )
};

export default HeaderButton;