import React, { useState } from 'react';
import '../styles/horizontalAccordion.css';

function HorizontalAccordion({ items }) {
  const [openItemIndices, setOpenItemIndices] = useState([]);

  const toggleAccordion = (index) => {
    if (openItemIndices.includes(index)) {
      setOpenItemIndices(openItemIndices.filter((itemIndex) => itemIndex !== index));
    } else {
      setOpenItemIndices([...openItemIndices, index]);
    }
  };

  const isItemOpen = (index) => openItemIndices.includes(index);

  return (
    <div className="horizontal-accordion">
      {items.map((item, index) => (
        <div
          key={index}
          className={`accordion-item ${isItemOpen(index) ? 'open' : ''}`}
        >
          <div className="accordion-header" onClick={() => toggleAccordion(index)}>
            <div className="title">{item.title}</div>
            <div className={`toggle-arrow ${isItemOpen(index) ? 'open' : ''}`}></div>
          </div>
          <div className="accordion-content">
            {isItemOpen(index) && <p>{item.content}</p>}
          </div>
        </div>
      ))}
    </div>
  );
}

export default HorizontalAccordion;
