import React, { useState } from 'react';

const OpportunityHubFilters = () => {
  const [remoteJobsOnly, setRemoteJobsOnly] = useState(false);

  return (
    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', padding: '50px' }}>
      <div style={{ flex: 1, marginRight: '16px' }}>
        <label htmlFor="specializations">Specializations</label>
        <input type="text" id="specializations" />
      </div>

      <div style={{ flex: 1, marginRight: '16px' }}>
        <label htmlFor="locations">Locations</label>
        <input type="text" id="locations" />
      </div>

      <div style={{ flex: 1, marginRight: '16px' }}>
        <label htmlFor="contractTypes">Contract types</label>
        <input type="text" id="contractTypes" />
      </div>

      <div style={{ display: 'flex', alignItems: 'center' }}>
      <input
          type="checkbox"
          id="remoteJobsOnly"
          checked={remoteJobsOnly}
          onChange={() => setRemoteJobsOnly(!remoteJobsOnly)}
        />
        <label htmlFor="remoteJobsOnly" style={{ marginRight: '8px' }}>
          Remote jobs only
        </label>
      </div>
    </div>
  );
};

export default OpportunityHubFilters;
