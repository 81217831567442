// JobApplicationForm.jsx
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../styles/jobApplicationForm.css';

const JobApplicationForm = ({ status, salaryRange }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    resume: null,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, resume: e.target.files[0] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    // Perform the submission logic here, such as sending to an API
  };

  return (
    <form className="job-application-form" onSubmit={handleSubmit}>
      <div className="status">{status}</div>
      <div className="salary-range">{salaryRange}</div>
      <div className="freelance">Before Tax (Freelance)</div>

      <label htmlFor="name">What is your name?*</label>
      <input
        type="text"
        id="name"
        name="name"
        placeholder="Enter your name"
        required
        onChange={handleInputChange}
      />

      <label htmlFor="email">What is your email address?*</label>
      <input
        type="email"
        id="email"
        name="email"
        placeholder="Enter your email"
        required
        onChange={handleInputChange}
      />

      <label htmlFor="phone">What is your phone number?*</label>
      <input
        type="tel"
        id="phone"
        name="phone"
        placeholder="Enter your number"
        required
        onChange={handleInputChange}
      />

      <label className="file-upload-label">
        Drag and drop file here or
        <span className="browse">browse</span>
        <input
          type="file"
          id="resume"
          name="resume"
          accept=".pdf,.txt,.png,.jpg,.gif"
          onChange={handleFileChange}
        />
      </label>

      <button type="submit" className="apply-button">APPLY NOW</button>
    </form>
  );
};

JobApplicationForm.propTypes = {
  status: PropTypes.string.isRequired,
  salaryRange: PropTypes.string.isRequired
};

export default JobApplicationForm;
