// JobListing.jsx
import React from 'react';
import PropTypes from 'prop-types';
import '../styles/jobListing.css';

const JobListing = ({ title }) => {
  return (
    <div className="job-listing">
      <h1 className="job-title">{title}</h1>
      <p className="intro-text">
        We are a leading technology company proud of our innovation and seeking exceptional talents to join our team. We value excellence, creativity, and a commitment to quality.
      </p>
      <h2>Position Available: Senior Frontend Developer</h2>
      <section className="requirements">
        <h3>Requirements:</h3>
        <ul>
          <li>Minimum of 5 years of experience in frontend development.</li>
          <li>Proficiency in HTML, CSS, and JavaScript.</li>
          <li>Experience with modern frameworks such as React or Angular.</li>
          <li>Strong knowledge of software architecture.</li>
          <li>Problem-solving and debugging skills.</li>
          <li>Strong communication and collaboration skills.</li>
        </ul>
      </section>
      <section className="responsibilities">
        <h3>Responsibilities:</h3>
        <ul>
          <li>Develop attractive and functional user interfaces.</li>
          <li>Collaborate with cross-functional teams to translate business requirements into technical solutions.</li>
          <li>Maintain and enhance existing applications.</li>
          <li>Provide technical leadership and mentorship to junior developers.</li>
        </ul>
      </section>
      <section className="offer">
        <h3>What We Offer:</h3>
        <ul>
          <li>Innovative and collaborative work environment.</li>
          <li>Ongoing learning and professional development opportunities.</li>
          <li>Competitive benefits package.</li>
          <li>Attractive salary based on experience.</li>
        </ul>
      </section>
      <p className="call-to-action">
        If you are a talented frontend developer with a proven track record, we look forward to welcoming you to our team. Please submit your resume and portfolio to [your contact email] and join us in creating world-class solutions.
      </p>
    </div>
  );
};

JobListing.propTypes = {
  title: PropTypes.string.isRequired
};

export default JobListing;
