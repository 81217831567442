import '../styles/knowHowCard.css';

const KnowHowCard = (props) => {
    const { imageUrl, title, itemList, leftLine, rightLine } = props;

    return (
        <div className="know-how-card">
            <div className="know-how-card-top">
                <img src={imageUrl} alt={title} className="know-how-card-image" />
                {leftLine && (<div className="know-how-card-left-element"></div>)}
                <div className="know-how-card-center-element"></div>
                {rightLine && (<div className="know-how-card-right-element"></div>)}
            </div>
            <div className="know-how-card-content">
                <h2 className="know-how-card-title">{title}</h2>
                {itemList.map((item, index) => (<p className="know-how-card-description">{item}</p>))}
            </div>
        </div>
    );
}

export default KnowHowCard;