import React from "react";
import TechedgeHeader from "../components/Headers/TechedgeHeader";
import ClientCarrousel from "../components/ClientCarrousel";
import '../styles/sections.css';
import '../styles/techEdgePage.css';
import OurTechnologyCardContainer from "../components/OurTechnologyCardContainer";
import DigitalTraces from "../components/DigitalTraces";
import OurValuesCard from "../components/OurValuesCard";
import KnowHowCardContainer from "../components/KnowHowCardContainer";

const Techedge = () => {

    return (
        <>
            <TechedgeHeader />
            <div className={'centering'}>
                <div className="container">
                    <div className="techedge-our-methodology-container">
                        <div className="techedge-our-methodology-title">
                            <div className="section-title">
                                <img src={'images/numbers/01.png'} />
                                <h3 className="section-title-text">Areas of our<br />Know-how</h3>
                            </div>
                        </div>
                        <div className="techedge-our-methodology-text">
                            <p>We define our skills as multidisciplinary, innovative, and consistent.
                                We are able to face even the most difficult challenges fearlessly thanks to our technological expertise gained through multiple projects.
                                We connect you with exceptional players.</p>
                        </div>
                    </div>
                    <div className="container">
                        <KnowHowCardContainer />
                    </div>
                </div>
                <div className={'clients-wrapper'}>
                    <div className='container'>
                        {/* Clients Section */}
                        <div className="section-title nimber-margin-bottom-52" style={{ width: '80vw', overflowX: "hidden" }}>
                            <img src={'images/numbers/02.png'} alt={'2'} />
                            <h3 className="section-title-text">We work with the latest technologies</h3>
                        </div>
                        <ClientCarrousel />
                    </div>
                </div>
                <div className="container">
                    <div className="techedge-our-methodology-container">
                        <div className="techedge-our-methodology-title">
                            <div className="section-title">
                                <img src={'images/numbers/03.png'} />
                                <h3 className="section-title-text">Our Methodology</h3>
                            </div>
                        </div>
                        <div className="techedge-our-methodology-text">
                            <p>We deliver value. We know what it takes to maintain our stripes of a trusted
                                technology nearshore provider because we employ the appropriate strategy, talent,
                                and expertise to advance your company. What's the secret sauce? It's not a lot of wisdom -
                                we just do our best, have fun and play with our top-of-the-game talents.</p>
                        </div>
                    </div>
                </div>
                <div className="container" style={{ width: '100%' }}>
                    <OurTechnologyCardContainer />
                </div>
                <div className="container">
                    <div className="techedge-our-methodology-container">
                        <div className="techedge-our-methodology-title">
                            <div className="section-title">
                                <img src={'images/numbers/04.png'} />
                                <h3 className="section-title-text">Why Nimber</h3>
                            </div>
                        </div>
                        <div className="techedge-our-methodology-text">
                            <p>Every route leads to Nimber. Companies are being forced to adopt a new culture of remote.
                                Did you think you can see this as an opportunity to enhance your hiring practices?</p>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="centering">
                        <div className="techedge-why-nimber-detailed-description">
                            <p>
                                We use a method that works to find, hire, put together, train, and keep track of highly skilled teams that<br />
                                are tailored to the project. Our work model includes constant and open communication with the client in<br />
                                addition to periodic feedback.<br />
                                Our people are Nimbers, not numbers.
                            </p>
                        </div>
                        <div className='techedge-why-nimber-cards'>
                            <OurValuesCard>
                                <div>
                                    <img className='ourValues-img' src='images/ourValues/01.png' />
                                    <p className='ourValues-text'>
                                        Training
                                    </p>
                                </div>
                            </OurValuesCard>
                            <OurValuesCard>
                                <div>
                                    <img className='ourValues-img' src='images/ourValues/01.png' />
                                    <p className='ourValues-text'>
                                        Innovation
                                    </p>
                                </div>
                            </OurValuesCard>
                            <OurValuesCard>
                                <div>
                                    <img className='ourValues-img' src='images/ourValues/01.png' />
                                    <p className='ourValues-text'>
                                        Commitment
                                    </p>
                                </div>
                            </OurValuesCard>
                            <OurValuesCard>
                                <div>
                                    <img className='ourValues-img' src='images/ourValues/01.png' />
                                    <p className='ourValues-text'>
                                        flexibility
                                    </p>
                                </div>
                            </OurValuesCard>
                        </div>
                    </div>
                </div>
                <div className={'clients-wrapper'}>
                    <div className='container'>
                        <div className="section-title">
                            <img src={'images/numbers/05.png'} />
                            <h3 className="section-title-text">Digital Traces</h3>
                        </div>
                        <DigitalTraces />
                    </div>
                </div>
                <div className={'clients-wrapper'} style={{ padding: '0px' }}>
                    <div className="container" style={{ width: '100%', padding: '0px' }}>
                        <div className="techedge-schedule-call-container">
                            <div className="techedge-schedule-call-image">
                                <h3>Schedule a call<br />with us</h3>
                                <div className="techedge-schedule-call-title-image">
                                    <img src="/images/TempCEO.png" />
                                    <div className="techedge-schedule-call-details-container">
                                        <h4 className="techedge-schedule-call-name">Name</h4>
                                        <span className="techedge-schedule-call-title">CEO</span>
                                    </div>
                                </div>
                            </div>
                            <div className="techedge-schedule-call-form">
                                <form>
                                    <h3 className="techedge-schedule-call-form-space-24">Let's work together</h3>
                                    <div className="techedge-schedule-call-form-group techedge-schedule-call-form-space-24">
                                        <label for="txtName">Name*</label>
                                        <input id="txtName" type="text" placeholder="Enter your name" />
                                    </div>
                                    <div className="techedge-schedule-call-form-group techedge-schedule-call-form-space-24">
                                        <label for="txtEmail">Email*</label>
                                        <input id="txtEmail" type="email" placeholder="Enter your email" />
                                    </div>
                                    <div className="techedge-schedule-call-form-group techedge-schedule-call-form-space-24">
                                        <label for="txtPhone">Email*</label>
                                        <input id="txtPhone" type="tel" placeholder="(750) 932-2345" />
                                    </div>
                                    <div className="techedge-schedule-call-form-group techedge-schedule-call-form-space-24">
                                        <label for="txtCompany">Company*</label>
                                        <input id="txtCompany" type="text" placeholder="Enter your Company Name" />
                                    </div>
                                    <div className="techedge-schedule-call-form-group techedge-schedule-call-form-space-24">
                                        <button type="submit">
                                            SET UP A MEETING
                                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" style={{ fill: 'white' }}>
                                                <path d="M16.1 260.2c-22.6 12.9-20.5 47.3 3.6 57.3L160 376V479.3c0 18.1 14.6 32.7 32.7 32.7c9.7 0 18.9-4.3 25.1-11.8l62-74.3 123.9 51.6c18.9 7.9 40.8-4.5 43.9-24.7l64-416c1.9-12.1-3.4-24.3-13.5-31.2s-23.3-7.5-34-1.4l-448 256zm52.1 25.5L409.7 90.6 190.1 336l1.2 1L68.2 285.7zM403.3 425.4L236.7 355.9 450.8 116.6 403.3 425.4z" /></svg>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Techedge