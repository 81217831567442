// Header.js
import React from 'react';
import '../../styles/header.css';
import HeaderButton from '../HeaderButton';

const NearshoreHeader = () => {
    const menuItems = [
        {
            title: 'Nimberverse',
            path: '/nimberverse',
            selected: false
        },
        {
            title: 'TechEdge',
            path: '/techedge',
            selected: false
        },
        {
            title: 'Nearshore',
            path: '/nearshore',
            selected: true
        },
        {
            title: 'Opportunity Hub',
            path: '/opportunity-hub',
            selected: false
        },
        {
            title: 'Outer World',
            path: '/outer-world',
            selected: false
        }
    ];

    return (
        <div className="header centering">
            <div className={'video-container'}>
                <video className="video" autoPlay loop muted>
                    <source src="/header2.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>

            <div className="header-menu-row centering" style={{ justifyContent: "center" }}>
                <div className={'container'}>
                    <div className={'logo-holder'}>
                    <a href="/"><img className="logo" src="images/Logo-white.png" alt="Logo" /></a>
                        <div className="menu">
                            {menuItems.map((item, index) => (
                                <a key={index} className={`menu-item header-menu-item-text ${item.selected === true ? 'header-menu-item-selected' : ''}`} href={`${item.path}`}>
                                    {item.title}
                                    {item.selected === true && (<div className='menu-item-selected-bar'>___</div>)}
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className={'container'} style={{ height: '100%' }}>
                <div className={"header-block-text"}>
                    <span className={'header-top-text'}>WHY NEARSHORE?</span>
                    <h3 className={'header-top-title'}>WE BRING YOUR PROJECTS
                        TO LIFE
                        BY HIRING
                        A TEAM OF EXPERTS</h3>
                    <HeaderButton text={'SEE OPEN POSITIONS'} onClick={() => { }} /> 
                    <p className={'header-top-subtext'}>Delivering the right skills to any business — when and how they're needed.</p>
                </div>
            </div>
        </div>
    );
};

export default NearshoreHeader;
