import ContentImage from "../components/ContentImage";
import React from "react";
import Talent from "../components/Talent";
import '../styles/nearshore.css'
import DigitalTraces from "../components/DigitalTraces";
import ScheduleCall from "../components/ScheduleCall";
import ClientCarrousel from "../components/ClientCarrousel";
import NearshoreHeader from "../components/Headers/NearshoreHeader";

const Nearshore = () => {

    return (
        <>
            <NearshoreHeader />
            <div className={'centering'}>
                <div className='container'>
                    {/* Clients Section */}
                    <div className="section-title nimber-margin-bottom-52">
                        <img src={'images/numbers/01.png'} alt={'1'} />
                        <h3 className="nearshore-section-title-text">We work with the latest technologies</h3>
                    </div>
                    <ClientCarrousel />

                {/*   */}
                {/* Why Nearshoring Section */}
                <div className={'nearshore-section02'}>
                    <div className='nearshore-section02a'>
                        <div className="section-title nearshore-section02a-title">
                            <img src={'images/numbers/02.png'} alt={'2'}/>
                            <h3 className="nearshore-section-title-text">Why Nearshoring?</h3>
                        </div>
                        <p className='nearshore-section03a-text02'>
                            We choose the brave players, with the right knowledge and detailed implementation. Pretty insightful, isn’t it?
                        </p>
                    </div>
                    <div className={'nearshore-three-block'}>
                        <div className={'nearshore-single-block'}>
                            <p className={''}>Less effort regarding resources and career management, but still highly qualified and multitask</p>
                        </div>
                        <div className={'nearshore-single-block'}>
                            <div>
                                <img src={'images/growing/Location.png'} alt={'Location'}/>
                            </div>
                            <p className={''}>Prime Location</p>
                        </div>
                        <div className={'nearshore-single-block'}>
                            <div>
                                <img src={'images/growing/Culture.png'} alt={'Culture'}/>
                            </div>
                            <p className={''}>Culture and Diversity</p>
                        </div>
                    </div>
                    <div className={'nearshore-three-block'}>
                        <div className={'nearshore-single-block-large'}>
                            <div>
                                <img src={'images/growing/Players.png'} alt={'Players'}/>
                            </div>
                            <p className={''}>Nimber Players</p>
                        </div>
                        <div className={'nearshore-single-block-large'}>
                            <div>
                                <img src={'images/growing/Legal.png'} alt={'Legal'}/>
                            </div>
                            <p className={''}>Legal and Focus</p>
                        </div>
                    </div>
                </div>
                {/*   */}
                {/* Why Portugal section */}
                <div>
                    <ContentImage
                        imgSrc={'images/WhyPT.png'}
                        imagePosition={'left'}>
                        <div className='nearshore-section03a'>
                            <div className="section-title">
                                <img src={'images/numbers/03.png'} alt={'3'}/>
                                <h3 className="nearshore-section-title-text">Why Portugal?</h3>
                            </div>
                            <p className='nearshore-section03a-text02'>
                                From a technical or creative standpoint as well as an investment standpoint,
                                Portugal is extremely competitive on global markets. Such an opportunity, isn't it?
                            </p>
                        </div>
                    </ContentImage>
                    <div className={'nearshore-three-block'}>
                        <div className={'nearshore-single-item'}>
                            <div>
                                <img src={'images/growing/Culture.png'} alt={'Culture'}/>
                            </div>
                            <p className={'nimber-margin-0 nearshore-single-item-title'}>Qualified Human Resources</p>
                            <p className={'nimber-margin-0 nearshore-single-item-description'}>(in terms of professional, interpersonal, and language skills)</p>
                        </div>
                        <div className={'nearshore-single-item'}>
                            <div>
                                <img src={'images/growing/Legal.png'} alt={'Legal'}/>
                            </div>
                            <p className={'nimber-margin-0 nearshore-single-item-title'}>Quality and growth
                                of infrastructures</p>
                            <p className={'nimber-margin-0 nearshore-single-item-description'}>(in terms of professional, interpersonal, and language skills)</p>
                        </div>
                        <div className={'nearshore-single-item'}>
                            <div>
                                <img src={'images/growing/Location.png'} alt={'Location'}/>
                            </div>
                            <p className={'nimber-margin-0 nearshore-single-item-title'}>Lower labor costs
                                and greater labor flexibility</p>
                            <p className={'nimber-margin-0 nearshore-single-item-description'}>(comparison to other European nations)</p>
                        </div>
                    </div>
                    <div className={'nearshore-three-block'}>
                        <div className={'nearshore-single-item'}>
                            <div>
                                <img src={'images/growing/Players.png'} alt={'Players'}/>
                            </div>
                            <p className={'nimber-margin-0 nearshore-single-item-title'}>One of the world's most prestigious</p>
                            <p className={'nimber-margin-0 nearshore-single-item-description'}>innovative, sustainable, and entrepreneurial cities is Lisbon.</p>
                        </div>
                        <div className={'nearshore-single-item'}>
                            <div>
                                <img src={'images/growing/TalentCost.png'} alt={'TalentCost'}/>
                            </div>
                            <p className={'nimber-margin-0 nearshore-single-item-title'}>Ability to adapt</p>
                            <p className={'nimber-margin-0 nearshore-single-item-description'}>to various markets and cultures</p>
                        </div>
                        <div className={'nearshore-single-item'}>
                            <div>
                                <img src={'images/growing/Culture.png'} alt={'Culture'}/>
                            </div>
                            <p className={'nimber-margin-0 nearshore-single-item-title'}>Good weather, good quality of life</p>
                            <p className={'nimber-margin-0 nearshore-single-item-description'}>and a safe place to live.</p>
                        </div>
                    </div>
                </div>
                {/*   */}


                    {/* Talent Section */}
                    <div className={'nearshore-center'}>
                        <div className="section-title">
                            <img src={'images/numbers/04.png'} alt={'4'} />
                            <h3 className="nearshore-section-title-text">Thriving in Diversity,<br></br>Culture and Talent</h3>
                        </div>
                        <p>Learn more about the perks & benefits we offer.</p>
                        <Talent />
                    </div>
                    {/*    */}

                    {/* Schedule a call section */}
                    <ScheduleCall />
                    {/*    */}

                </div>
            </div>
        </>
    )
}

export default Nearshore