import React from "react";
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './pages/home';
import './styles/main.css';
import './styles/fonts.css';
import Nearshore from "./pages/nearshore";
import Footer from "./components/Footer";
import Nimberverse from "./pages/nimberverse";
import Techedge from "./pages/techedge";
import OpportunityHub from "./pages/opportunityHub";
import OfferPage from "./pages/offer";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />
    },
    {
        path: "/nearshore",
        element: <Nearshore />
    },
    {
        path: "/nimberverse",
        element: <Nimberverse />
    },
    {
        path: "/techedge",
        element: <Techedge />
    },
    {
        path: "/opportunity-hub",
        element: <OpportunityHub />
    },
    {
        path: "/offer",
        element: <OfferPage />
    }
]);

function App() {
    return (
        <React.StrictMode>
            <RouterProvider router={router} />
            <Footer />
        </React.StrictMode>
    );
}

export default App;