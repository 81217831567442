import IconButtonBlue from "./IconButtonBlue";
import 'font-awesome/css/font-awesome.min.css';
import '../styles/opportunityHubCard.css';

const OpportunityHubCard = ({ title, subTitle, status, contractType, location }) => {
    return (
        <div className="opportunity-hub-card">
            <h4>{title}</h4>
            <h4>{subTitle}</h4>
            <span>{status}</span>
            <div className="opportunity-hub-card-regime-container">
            <div className="opportunity-hub-card-regime">Regime</div>
            </div>
            <div className="opportunity-hub-card-contract">
                <i className="fa fa-file-o"></i>
                <p>{contractType}</p>
            </div>
            <div className="opportunity-hub-card-location">
                <i className="fa fa-map-marker"></i>
                <p>{location}</p>
            </div>
            <div>
                <IconButtonBlue text={'APPLY'} iconClass={'fa fa-arrow-right'} onclick={() => window.location.href = "/offer"} />
            </div>
        </div>
    );
};

export default OpportunityHubCard;