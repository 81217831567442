import React from 'react';
import PropTypes from 'prop-types';
import '../styles/backButton.css';

const BackButton = ({ text, onClick }) => {
  return (
    <button className="back-button" onClick={onClick}>
      <span className="back-arrow">←</span>
      {text}
    </button>
  );
};

BackButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

BackButton.defaultProps = {
  onClick: () => window.history.back()
};

export default BackButton;
