import React, { useState } from 'react';
import OurTechnologyCard from './OurTechnologyCard';
import '../styles/ourTechnologyCardContainer.css';

const OurTechnologyCardContainer = () => {
    const technologyData = [
        {
            id: 1,
            imageSrc: '/images/ourMethodology/outsourcing.png',
            title: 'Outsourcing',
            customContent: (
                <p>
                    Outsourcing involves <span>hiring external service providers to secure and provide various IT solutions and operations.</span> These encompass software development, system maintenance, cybersecurity, data management, and cloud computing, among others.
                    <br /><br />
                    Outsourcing comes in three primary forms: <span>onshore</span> (within your country), <span>nearshore</span> (in a neighboring country), and <span>offshore</span> (in a distant location).
                </p>
            )
        },
        {
            id: 2,
            imageSrc: '/images/ourMethodology/nearshore.png',
            title: 'Nearshore',
            customContent: (
                <p>
                    Businesses and startups globally have embraced <span>software development outsourcing for its numerous benefits.</span> 
                    At Nimber, we cater to your IT requirements, providing nearshore and fully remote solutions, no matter your location.
                </p>
            )
        },
        {
            id: 3,
            imageSrc: '/images/ourMethodology/turnkey-projects.png',
            title: 'Turn Keys Projects',
            customContent: (
                <p>
                    Turnkey projects, undertaken by IT service providers such as Nimber, <span>involve end-to-end design, development, and delivery to clients, ensuring they receive a fully functional solution aligning with initial requirements and agreed-upon terms.</span>
                    <br /><br />
                    The term "turnkey" signifies a seamless process, enabling clients to focus on other priorities while the project takes shape. In the end, they can effortlessly "open the door" and start using it.
                    <br /><br />
                    This approach proves highly effective for software development projects, as it empowers companies to create new applications and software products without requiring in-house expertise and minimizing time investments.
                </p>
            )
        },
    ];

    const [selectedCard, setSelectedCard] = useState(technologyData[0].id);
    const [selectedContent, setSelectedContent] = useState(technologyData[0].customContent);

    const cardClickHandler = (technology) => {
        setSelectedCard(technology.id);
        setSelectedContent(technology.customContent);
    };

    return (
        <div className="technology-card-container">
            {technologyData.map((technology) => (
                <OurTechnologyCard
                    key={technology.id}
                    imageSrc={technology.imageSrc}
                    title={technology.title}
                    isSelected={selectedCard == technology.id}
                    onClick={() => cardClickHandler(technology)}
                />
            ))}
            {selectedCard !== null && (
                <div className="techedge-our-methodology-detailed-description">
                    <div className='techedge-our-methodology-detailed-description-text-align'>
                        {selectedContent}
                    </div>
                </div>
            )}
        </div>
    );
};

export default OurTechnologyCardContainer;
