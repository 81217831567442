import '../../styles/talent.css';

function Optimizing () {
    return(
        <div className={'nimber-talent-block'}>
            <div className={'nimber-talent-left'}>
                <h1 className={'nimber-main-title nimber-foundation-neutral-500'}>Optimizing talent<br></br>investment</h1>
                <p className={'nimber-secondary-text nimber-foundation-neutral-500'}>Portugal sets itself apart from other European nations. Despite having a lower labor cost, our workforce is highly skilled and capable of taking on a multitude of tasks with ease. This unique combination makes us a true gem in the competitive world of business.</p>
            </div>
            <div className={'nimber-talent-img'}>
                <img src={'images/talent/Talent.png'} alt={'Talent'} />
            </div>
        </div>
    )
}

export default Optimizing