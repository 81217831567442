import React from "react";
import 'font-awesome/css/font-awesome.min.css';
import '../styles/iconButton.css';

const IconButton = ({ text, iconClass, onClick }) => {
    return (
        <button className="icon-button" onClick={onClick}>
            {text} <i className={iconClass}></i>
        </button>
    )
};

export default IconButton;