import BackButton from "../components/BackButton";
import OfferHeader from "../components/Headers/OfferHeader";
import JobApplicationForm from "../components/JobApplicationForm";
import JobListing from "../components/JobListing";
import '../styles/offer.css';

const OfferPage = () => {

    return (
        <>
            <OfferHeader />
            <div className="centering">
                <div className="container" style={{ width: '100%' }}>
                    <div style={{ width: '100%' }}>
                        <BackButton text="BACK TO OPPORTUNITY HUB" />
                    </div>
                </div>
                <div className="container" style={{ paddingTop: '0px'}}>
                    <div className="job-listing-container">
                        <div className="job-listing-content">
                        <JobListing title="Frontend Developer (m/f)" />
                        </div>
                    <div className="job-listing-upload-content">
                        <JobApplicationForm status="ONGOING" salaryRange="EUR 90-110/H" />
                    </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default OfferPage;