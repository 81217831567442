import React from "react";
import NimberverseHeader from "../components/Headers/NimberverseHeader";
import SectionTitle from '../components/SectionTitle';
import OurPersonality from '../components/OurPersonality';
import OurValues from '../components/OurValues';
import '../styles/nimberverse.css';
import '../styles/home.css';
import HorizontalAccordion from "../components/HorizontalAccordion";
import HeaderButton from "../components/HeaderButton";
import PeopleAreKeyCardContainer from "../components/PeopleAreKeyCardContainer";
import WeAreLookingForNimbers from "../components/WeAreLookingForNimbers";
import bgBrazilianFriends from '../components/bg-brazilian-friends.png';
import brazilianFriendsBg from '../components/brazilian-friends-bg.svg';
import brazilImg from '../components/brazil-image.png';
import ourPersonalityBg from '../components/our-methodology-bg.svg';

const Nimberverse = () => (
    <>
        <NimberverseHeader />
        <div className={'centering'}>
            <div className="container">
                <div className="nimberverse-it-challenges-container">
                    <div className="nimberverse-it-challenges-title">
                        <div className="section-title">
                            <img src={'images/numbers/01.png'} />
                            <h3 className="section-title-text">At Nimber we take
                                care of all your <span style={{ color: '#0227B0' }}>IT challenges.</span></h3>
                        </div>
                    </div>
                    <div className="nimberverse-it-challenges-text">
                        <p>We are a team of dedicated and skilled professionals,
                            driven by our passion for delivering exceptional results.
                            Let&#39;s work together to achieve your goals and take your business to the next level.</p>
                    </div>
                </div>
                <div className="nimberverse-ceo-container">
                    <div className="nimberverse-ceo-message-container">
                        <span className="nimberverse-ceo-message-first-quote">“</span>
                        <p className="nimberverse-ceo-message-text">“Quem nos conhece bem e percebe quem somos e tem relação connosco sabe que vamos
                            fazer diferente, (...) colocar os colaboradores o centro da empresa (...) não ter
                            problemas (...) se sintam motivadas (...) sintam integradas.”</p>
                    </div>
                    <div className="nimverser-ceo-image">
                        <img src="/images/ceo-picture.png" />
                        <div className="nimverser-ceo-details-container">
                            <h3 className="nimverser-ceo-name">Rui Neves</h3>
                            <span className="nimverser-ceo-title">CEO</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="nimberverse-our-name-container" style={{ backgroundColor: 'white', width: '100%' }}>
                    <div>
                        <SectionTitle title={'Our Name'} imgSrc={'images/numbers/02.png'} />
                        <p className="nimberverse-our-name-text">Because Nimber applies to game theory and results from the combination of two words,
                            “nim” and “number”. <span>Impartiality, Independence and Justice</span> are the main rules of the game at Nimber.
                            Wich is why, at Nimber, we want to do: <span>Add value to our clients projects</span></p>
                    </div>
                    <div>
                        <img src={'/images/OurName.png'} />
                    </div>
                </div>
            </div>
            <div className={'clients-wrapper'} style={{ backgroundColor: '#0227B0', paddingBottom: '0px', paddingTop: '0px' }}>
                <div className="nimberverse-nimber-container">
                    <div className="nimberverse-nimber-nimgame-text">
                        <p><span>NIM GAME:</span> Known as being part of the “Unbiased Games”</p>
                    </div>
                    <div className="nimberverse-nimber-nimgame-arrow">
                        <img src="/images/arrow-nim.png" alt='arrow-nim' />
                    </div>
                    <div className="nimberverse-nimber">
                        <div className="nimberverse-nimber-nim">NIM</div>
                        <div className="nimberverse-nimber-plus">+</div>
                        <div className="nimberverse-nimber-ber">BER</div>
                    </div>
                    <div className="nimberverse-nimber-number-arrow">
                        <img src="/images/arrow-ber.png" alt='arrow-ber' />
                    </div>
                    <div className="nimberverse-nimber-number-text">
                        <p><span>NUMBER:</span> an arithmetical value, representing a particular quantity and used counting.</p>
                    </div>
                </div>
            </div>
            <div className={'clients-wrapper'} style={{ backgroundColor: 'transparent', paddingBottom: '0px', paddingTop: '0px', marginTop: '-170px' }}>
                <div style={{
                    background: `url(${ourPersonalityBg})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    width: '100%'
                }}>
                    <div className="centering">
                        <div className="container">
                            <div className="nimberverse-our-personality-container">
                                <div className={'title-flex'}>
                                    <SectionTitle title={'Our Personality'} imgSrc={'images/numbers/03.png'} />
                                    <p className='section02a-text01'>The personality that makes sense for us to embrace.</p>
                                </div>
                                <div style={{ paddingBottom: '90px', paddingTop: '50px' }}>
                                    <OurPersonality />
                                </div>
                                <div style={{ paddingBottom: '90px', paddingTop: '50px' }}>
                                    <SectionTitle title={'Our Values'} imgSrc={'images/numbers/04.png'} />
                                    <OurValues />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="nimberverse-techfolio-container">
                <div className="nimberverse-techfolio-title-image">
                    <div style={{ paddingBottom: '90px', paddingTop: '50px' }}>
                        <SectionTitle title={'Our Techfolio'} imgSrc={'images/numbers/05.png'} />
                    </div>
                    <img src="/images/techfolio.png" className="nimberverse-techfolio-image" />
                </div>
                <HorizontalAccordion
                    items={[
                        { title: 'Frontend', content: 'React, Angular, Vue, Typescript, HTML, CSS' },
                        { title: 'Backend', content: 'java, .net, python, node js, php, C++, Go (Golang)' },
                        { title: 'Mobile', content: 'Android (Java, kotlin), IOS (Swift, objective-c), Xamarim, React Native, Flutter, Apache Cordova' },
                        { title: 'Middleware', content: 'TIBCO, Mulesoft, Oracle SOA suite' },
                        { title: 'Data', content: 'Business Inteligence, Big data, Machine Learning, data Science, IOT' },
                        { title: 'Cloud', content: 'GCP, Azure, AWS' },
                        { title: 'Functional', content: 'Scrum, Business Analysis, Project Management, Functional Testing' },
                    ]} />
            </div>
            <div className="nimberverse-people-are-key">
                <div className='nimberverse-people-are-key-title'>
                    <SectionTitle title={'People are the key to success'} imgSrc={'images/numbers/06.png'} />
                </div>
                <div className="nimberverse-people-are-key-description">
                    <p>We feel proud every time when someone new is joining</p>
                </div>
                <div className="nimberverse-people-are-key-button-container">
                    <HeaderButton text={'WORKING AT NIMBER'} onClick={() => { }} />
                </div>
                <div className="nimberverse-people-are-key-card-container">
                    <PeopleAreKeyCardContainer />
                </div>
            </div>

            <div className={'clients-wrapper'} style={{ backgroundColor: 'transparent', paddingBottom: '0px' }}>
                <div className="nimberverse-our-brazilian-friends" style={{
                    background: `url(${brazilianFriendsBg}), url(${brazilImg})`,
                    backgroundBlendMode: 'hard-light',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top center',
                    backgroundSize: 'cover'
                }}>
                    <div className="container">
                        <div className="nimberverse-our-brazilian-friends-two-columns">
                            <div className="nimberverse-our-brazilian-friends-title-column">
                                <h3>A Treat for Our Brasilian Friends</h3>
                            </div>
                            <div className="nimberverse-our-brazilian-friends-description-column">
                                <p>
                                    And the language in which we hire, in Portuguese from here or there, does it matter to a Nimber?
                                    <br /><br />
                                    We created a song to welcome our consultants from Brazil to Portugal, highlighting the differences in word meanings between the two countries.
                                </p>
                            </div>
                        </div>
                        <div className="nimberverse-our-brazilian-friends-verse-container">
                            <div className="nimberverse-our-brazilian-friends-verse-alignment">
                                <p>
                                    Eu digo bala, tu dizes rebuçado<br />
                                    Eu digo celular, tu dizes telemóvel<br />
                                    Arapuca, armadilha<br />
                                    Treco, piripaque<br />
                                    O que importa para um Nimber<br />
                                    não é mesmo o sotaque.
                                    <br /><br />
                                    Eu digo geladeira, tu dizes frigorífico<br />
                                    Eu venho de trem, tu vens de comboio<br />
                                    Choppinho, cervejinha<br />
                                    Papo, conversa<br />
                                    O que importa para um Nimber é… vice-versa.
                                    <br /><br />
                                    Eu gosto, é legal, tu gostas, é fixe<br />
                                    Eu vou com a galera, tu vais com a malta<br />
                                    A galera é fixe<br />
                                    A malta é legal<br />
                                    O que importa para um Nimber é ser social.
                                    <br /><br />
                                    Eu digo gramado, tu dizes relvado<br />
                                    Eu digo “tamo junto”, tu dizes “bora nessa”<br />
                                    Cachoeira, cascata<br />
                                    Cara, fulano<br />
                                    O que importa para um nimber<br />
                                    é ser bom ser humano.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={'footer-envelop'}>
                <div className='container'>
                    <WeAreLookingForNimbers />
                </div>
            </div>

        </div>
    </>
)

export default Nimberverse