import OpportunityHubHorizontalItem from "./OpportunityHubHorizontalItem";

const OpportunityHubHorizontalContainer = ({ data }) => {
    console.log("got data", data);
    return (
        <div style={{ width: '100%' }}>
            {data.map((item, index) => (
                <OpportunityHubHorizontalItem
                    title={item.title}
                    status={item.status}
                    regime={item.regime}
                    contractType={item.contractType}
                    location={item.location}
                />
            ))}
        </div>
    )
}

export default OpportunityHubHorizontalContainer;