import React from 'react';
import PeopleAreKeyCard from './PeopleAreKeyCard';
import '../styles/peopleAreKeyCardContainer.css';

const PeopleAreKeyCardContainer = () => {
    const cardData = [
        {
            id: 1,
            imageSrc: '/images/peopleAreKey.png',
            title: 'Rui Neves',
            jobRole: 'CEO',
            linkedinUrl: 'https://www.linkedin.com/',
        },
        {
            id: 2,
            imageSrc: '/images/peopleAreKey2.png',
            title: 'Paulo Silva',
            jobRole: 'COO',
            linkedinUrl: 'https://www.linkedin.com/',
        },
        {
            id: 3,
            imageSrc: '/images/peopleAreKey.png',
            title: 'Diogo Franco',
            jobRole: 'MANAGER',
            linkedinUrl: 'https://www.linkedin.com/',
        },
    ];

    return (
        <div className="people-are-key-card-container">
            {cardData.map((card) => (
                <PeopleAreKeyCard
                    key={card.id}
                    imageSrc={card.imageSrc}
                    title={card.title}
                    jobRole={card.jobRole}
                    linkedinUrl={card.linkedinUrl}
                />
            ))}
        </div>
    );
};

export default PeopleAreKeyCardContainer;
