import React from 'react';
import IconButtonBlue from "./IconButtonBlue";
import '../styles/opportunityHubHorizontalItem.css';
import 'font-awesome/css/font-awesome.min.css';
import { HiOutlineDocument } from "react-icons/hi2";
import { MdOutlineLocationOn } from "react-icons/md";



const OpportunityHubHorizontalItem = ({ title, status, regime, contractType, location }) => {
    console.log("render", { title, status, regime, contractType, location });
    return (
        <div className="opportunity-hub-horizontal-item-container">
            <div className="opportunity-hub-horizontal-item-header">
                <div className="opportunity-hub-horizontal-item-title">{title}</div>
                <IconButtonBlue text={'APPLY'} iconClass={'fa fa-arrow-right'} onclick={() => window.location.href = '/offer'} />
            </div>
            <div className="opportunity-hub-horizontal-item-content">
                <div className="opportunity-hub-horizontal-item-label opportunity-hub-horizontal-item-label-status">{status}</div>
                <div className='opportunity-hub-horizontal-item-label-regime-container'>
                <div className="opportunity-hub-horizontal-item-label opportunity-hub-horizontal-item-label-regime">{regime}</div>
                </div>
                <div className="opportunity-hub-horizontal-item-icon">
                    <HiOutlineDocument />
                    <span>&nbsp;{contractType}</span>
                </div>
                <div className="opportunity-hub-horizontal-item-icon">
                    <MdOutlineLocationOn />
                    <span>&nbsp;{location}</span>
                </div>
            </div>
        </div>
    );
};

export default OpportunityHubHorizontalItem;
