import React from 'react';
import KnowHowCard from './KnowHowCard';
import '../styles/knowHowCardContainer.css';

const KnowHowCardContainer = () => {
    const cardData = [
        {
            imageUrl: '/images/numbers/01.png',
            title: 'Software Engineering',
            itemList: ['Architecture', 'Application Development', 'Mobile Development', 'Functional Layer', 'Tests'],
            leftLine: false,
            rightLine: true
        },
        {
            imageUrl: '/images/numbers/02.png',
            title: 'Systems Engineering',
            itemList: ['Cloud', 'Systems Administration Database (Administration and Development)', 'Networking', 'TroubleShooting', 'Middleware'],
            leftLine: true,
            rightLine: true
        },
        {
            imageUrl: '/images/numbers/03.png',
            title: 'Data Engineering',
            itemList: ['Big Data', 'Data Science', 'Business Intelligence', 'Machine Learning'],
            leftLine: true,
            rightLine: false
        },
    ];

    return (
        <div className="know-how-card-container">
            {cardData.map((card, index) => (
                <KnowHowCard
                    key={index}
                    imageUrl={card.imageUrl}
                    title={card.title}
                    itemList={card.itemList}
                    leftLine={card.leftLine}
                    rightLine={card.rightLine}
                />
            ))}
        </div>
    );
};

export default KnowHowCardContainer;
