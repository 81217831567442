import { useState } from "react";
import '../styles/button.css'
import '../styles/form.css'
import '../styles/scheduleCall.css'

const ScheduleCall = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        companyName: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here
        console.log(formData); // You can access form data here
    };

    return (
        <div className={'schedule-wrapper'}>
            <div className={'nimber-width-50 contact-left'}>
                <h1 className={'call-schedule-text'}>Schedule a call<br></br>with us</h1>
                <div className={'contact-box'}>
                    <img src={'images/TempCEO.png'} alt={'CEO'}/>
                    <div>
                        <h2 className={'nimber-margin-0'}>Name</h2>
                        <p className={'nimber-margin-0'}>CEO</p>
                    </div>
                </div>
            </div>
            <div className={'nimber-width-50'}>
                <div className={'form-wrapper'}>
                    <h2 className={'nimber-clean form-head-text'}>Let's Work Together</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group nimber-g-margin-top-24">
                            <label htmlFor="name">Name*</label>
                            <input
                                className={'form-group-input'}
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                                placeholder={'Enter your name'}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email*</label>
                            <input
                                className={'form-group-input'}
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                placeholder={'Enter your email'}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="phoneNumber">Phone Number*</label>
                            <input
                                className={'form-group-input'}
                                type="tel"
                                id="phoneNumber"
                                name="phoneNumber"
                                value={formData.phoneNumber}
                                onChange={handleInputChange}
                                placeholder={'(750) 932-2345'}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="companyName">Company Name*</label>
                            <input
                                className={'form-group-input'}
                                type="text"
                                id="companyName"
                                name="companyName"
                                value={formData.companyName}
                                onChange={handleInputChange}
                                placeholder={'Enter your Company Name'}
                                required
                            />
                        </div>
                        <button type="submit" className={'nimber-button'}>SET UP A MEETING<img src={'images/icons/Paper_Plane.png'} alt={'Paper Plane'} /></button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ScheduleCall