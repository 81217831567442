import OpportunityHubHeader from "../components/Headers/OpportunityHubHeader";
import '../styles/opportunityHubPage.css';
import '../styles/sections.css';
import '../styles/nearshore.css';
import 'font-awesome/css/font-awesome.min.css';
import OpportunityHubHorizontalContainer from "../components/OpportunityHubHorizontalContainer";
import OpportunityHubFilters from "../components/OpportunityHubFilters";

const OpportunityHub = () => {

    const opportunityHubData = [
        {
            title: "Frontend Developer (m/f)",
            status: "ONGOING",
            regime: "Híbrido",
            contractType: "Remoto",
            location: "Portugal"
        },
        {
            title: "Frontend Developer (m/f)",
            status: "ONGOING",
            regime: "Híbrido",
            contractType: "Remoto",
            location: "Portugal"
        },
        {
            title: "Frontend Developer (m/f)",
            status: "ONGOING",
            regime: "Híbrido",
            contractType: "Remoto",
            location: "Portugal"
        },
        {
            title: "Frontend Developer (m/f)",
            status: "ONGOING",
            regime: "Híbrido",
            contractType: "Remoto",
            location: "Portugal"
        },
    ];

    return (
        <>
            <OpportunityHubHeader />
            <div className={'centering'}>
                <div className="container">
                    <div className="opportunity-hub-working-at-nimber-container">
                        <div className="opportunity-hub-working-at-nimber-title">
                            <div className="section-title">
                                <img src={'images/numbers/01.png'} />
                                <h3 className="section-title-text">Working at Nimber<br />you embrace<br />flexibility, trust, and<br />impactful decision-<br />making.</h3>
                            </div>
                        </div>
                        <div className="opportunity-hub-working-at-nimber-text">
                            <p>Nimber's mission is to empower visionaries and creators to transform the world with exceptional IT services.
                                Our values revolve around trust, ownership, and transparency.
                                Whether you prefer full-time ror emote work, we're thrilled! Our aim is to provide tailored opportunities just for you.</p>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="centering">
                        <div className="section-title">
                            <img src={'images/numbers/02.png'} />
                            <h3 className="section-title-text">Perks and benefits</h3>
                        </div>
                    </div>
                    <div className={'nearshore-three-block'}>
                        <div className={'nearshore-single-item'}>
                            <div>
                                <img src={'images/growing/Culture.png'} alt={'Remote Work'} />
                            </div>
                            <p className={'nimber-margin-0 nearshore-single-item-title'}>Remote Work</p>
                            <p className={'nimber-margin-0 nearshore-single-item-description'}>Work efficiently – from home, from the office, or remotely.</p>
                        </div>
                        <div className={'nearshore-single-item'}>
                            <div>
                                <img src={'images/growing/Legal.png'} alt={'Flexible hours'} />
                            </div>
                            <p className={'nimber-margin-0 nearshore-single-item-title'}>Flexible hours</p>
                            <p className={'nimber-margin-0 nearshore-single-item-description'}>Adjust your calendar to your needs.</p>
                        </div>
                        <div className={'nearshore-single-item'}>
                            <div>
                                <img src={'images/growing/Location.png'} alt={'The best Equipment'} />
                            </div>
                            <p className={'nimber-margin-0 nearshore-single-item-title'}>The best Equipment</p>
                            <p className={'nimber-margin-0 nearshore-single-item-description'}>Adjust your calendar to your needs.</p>
                        </div>
                    </div>
                    <div className={'nearshore-three-block'}>
                        <div className={'nearshore-single-item'}>
                            <div>
                                <img src={'images/growing/Players.png'} alt={'Private health Insurance'} />
                            </div>
                            <p className={'nimber-margin-0 nearshore-single-item-title'}>Private health Insurance</p>
                            <p className={'nimber-margin-0 nearshore-single-item-description'}>Generous private healthcare package.</p>
                        </div>
                        <div className={'nearshore-single-item'}>
                            <div>
                                <img src={'images/growing/TalentCost.png'} alt={'Fast-paced career path'} />
                            </div>
                            <p className={'nimber-margin-0 nearshore-single-item-title'}>Fast-paced career path</p>
                            <p className={'nimber-margin-0 nearshore-single-item-description'}>Opportunities for professional
                                development, a promotion, and a pay raise.</p>
                        </div>
                        <div className={'nearshore-single-item'}>
                            <div>
                                <img src={'images/growing/Culture.png'} alt={'Football Games'} />
                            </div>
                            <p className={'nimber-margin-0 nearshore-single-item-title'}>Football Games</p>
                            <p className={'nimber-margin-0 nearshore-single-item-description'}>Opportunities for professional
                                development, a promotion, and a pay raise.</p>
                        </div>
                    </div>
                </div>

                <div className="container" style={{ width: '100%' }}>
                    <OpportunityHubFilters />
                    <OpportunityHubHorizontalContainer data={opportunityHubData} />
                </div>

                <div className={'clients-wrapper'}>
                    <div className='container' style={{ marginBottom: '100px', marginTop: '100px' }}>
                        <div className="opportunity-hub-cant-find-container">
                            <h1>Can’t find what you’re looking for?</h1>
                            <p>We’re always looking for people with unique skills. Send us your CV and we’ll get in touch when we have an opening that matches your expectations.</p>
                            <button>
                                SEND APPLICATION <i className={'fa fa-arrow-right'}></i>
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default OpportunityHub;