import React from 'react';
import PropTypes from 'prop-types';
import '../styles/ourTechnologyCard.css'; 

const OurTechnologyCard = ({ imageSrc, title, onClick, isSelected }) => {
    const cardClickHandler = () => {
        onClick();
    };

  return (
    <div className={`our-technology-card ${isSelected ? 'our-technology-card-selected' : ''}`} onClick={cardClickHandler}>
      <img src={imageSrc} alt={title} className="card-image" />
      <div className="card-title-container">
        <div className="card-title">{title}</div>
      </div>
    </div>
  );
};

OurTechnologyCard.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default OurTechnologyCard;
