import React from "react";
import data from '../mock/opportunityHub.json';
import IconButton from "./IconButton";
import '../styles/opportunityHub.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import OpportunityHubCard from "./OpportunityHubCard";

const OpportunityHub = () => {
    const filters = ["Developer", "Backend", "Quality Assurance", "Data", "TIBCO", "SAP"];

    const handleFilterButtonClick = event => {
        const isSelected = event.target.classList.contains("opportunity-hub-btn-filter-selected");
        if (isSelected === true) {
            event.target.classList.remove("opportunity-hub-btn-filter-selected");
            event.target.classList.add('opportunity-hub-btn-filter-unselected');
        } else {
            event.target.classList.remove("opportunity-hub-btn-filter-unselected");
            event.target.classList.add('opportunity-hub-btn-filter-selected');
        }
    };

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        variableWidth: false,
        responsive: [
            {
                breakpoint: 768, // Adjust this breakpoint as needed
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                }
            }
        ]
    };

    return (
        <>
            <div className="opportunity-hub-search-section">
                <input type="text" placeholder="Ex: Data Science" className="opportunity-hub-search-input" />
                <IconButton text={'SEARCH'} iconClass={'fa fa-arrow-right'} />
            </div>
            {/* here we need to get a distinct list of tags from the opportunity collection */}
            <div className="opportunity-hub-button-filters">
                <p className="opportunity-hub-or-search-for">or search for:</p>
                {filters.map((item, index) => (
                    <button key={`opportunityHubButton${index}`} className="opportunity-hub-btn-filter opportunity-hub-btn-filter-unselected" onClick={handleFilterButtonClick}>
                        {item}
                    </button>
                ))}
            </div>
            <div className="opportunity-hub-cards">
                <Slider  {...settings}>
                    {data.map((item, index) => (
                        <OpportunityHubCard
                            key={`opportunityHubCard${index}`}
                            title={item.title}
                            subTitle={item.subTitle}
                            status={item.status}
                            contractType={item.contractType}
                            location={item.location} />
                    ))}
                </Slider>
            </div>
        </>
    );
};

export default OpportunityHub;