import '../styles/sendUsYourCVForm.css';

const SendUsYourCVFile = (props) => {
    const { file, removeCallback } = props;
    const { name } = file;

    return (
        <div className="sendUsYourCVForm-attached-file">
            <div>
                <i className="fa fa-file-o sendUsYourCVForm-file" aria-hidden="true">&nbsp;</i>
                <span className="sendUsYourCVForm-file sendUsYourCVForm-file-link sendUsYourCVForm-file-clicable">{name}</span>
            </div>
            <i className="fa fa-trash sendUsYourCVForm-file sendUsYourCVForm-file-clicable" aria-hidden="true" onClick={removeCallback}></i>
        </div>
    );
}

export default SendUsYourCVFile;