import '../../styles/talent.css';

function Talented () {
    return(
        <div className={'nimber-talent-block'}>
            <div className={'nimber-talent-left'}>
                <h1 className={'nimber-main-title nimber-foundation-neutral-500'}>Our talented pool<br></br>of Nimber players</h1>
                <p className={'nimber-secondary-text nimber-foundation-neutral-500'}>Portugal is a country that offers a high standard of living, with a beautiful landscape and a rich cultural heritage. This quality of life can attract and retain high-quality talent, which is vital for establishing teams that can excel and deliver exceptional results.
                    It’s an attractive location for talented professionals who are looking for a great work-life balance.</p>
            </div>
            <div className={'nimber-talent-img'}>
                <img src={'images/talent/NimberPlayers.png'} alt={'Nimber Players'} />
            </div>
        </div>
    )
}

export default Talented